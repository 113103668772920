/* Base styles and variables */
:root {
  /* Colors */
  --primary-blue: #0075FF;
  --primary-green: #00C853;
  --secondary-blue: #64B5F6;
  --secondary-green: #69F0AE;
  --dark-blue: #0056b3;
  --dark-green: #00953d;
  --light-blue: #E3F2FD;
  --light-green: #E8F5E9;
  --light-gray: #F8F9FA;
  --dark-gray: #343A40;
  --medium-gray: #6c757d;
  --white: #FFFFFF;
  --text-gray: #495057;
  --success: #28a745;
  --warning: #ffc107;
  --error: #dc3545;
  --info: #17a2b8;
  
  /* Gradients */
  --gradient-blue-green: linear-gradient(135deg, var(--primary-blue) 0%, var(--primary-green) 100%);
  --gradient-green-blue: linear-gradient(135deg, var(--primary-green) 0%, var(--primary-blue) 100%);
  --gradient-blue-light: linear-gradient(135deg, var(--primary-blue) 0%, var(--secondary-blue) 100%);
  --gradient-green-light: linear-gradient(135deg, var(--primary-green) 0%, var(--secondary-green) 100%);
  
  /* Typography */
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Roboto', sans-serif;
  
  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.05);
  --shadow-md: 0 4px 8px rgba(0,0,0,0.1);
  --shadow-lg: 0 8px 16px rgba(0,0,0,0.1);
  --shadow-xl: 0 12px 24px rgba(0,0,0,0.15);
  
  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-round: 50%;
}

body {
  margin: 0;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-gray);
  line-height: 1.6;
  background-color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--dark-gray);
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 1rem;
  color: var(--text-gray);
}

a {
  color: var(--primary-blue);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--dark-blue);
  text-decoration: underline;
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* MUI Overrides */
.MuiButton-root {
  font-family: var(--body-font) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  border-radius: var(--radius-md) !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.MuiButton-containedPrimary {
  background-color: var(--primary-blue) !important;
}

.MuiButton-containedPrimary:hover {
  background-color: var(--dark-blue) !important;
  transform: translateY(-2px) !important;
  box-shadow: var(--shadow-lg) !important;
}

.MuiButton-outlinedPrimary {
  border-color: var(--primary-blue) !important;
  color: var(--primary-blue) !important;
}

.MuiButton-outlinedPrimary:hover {
  background-color: rgba(0, 117, 255, 0.04) !important;
  border-color: var(--dark-blue) !important;
  transform: translateY(-2px) !important;
}

.MuiAppBar-root {
  background-color: var(--white) !important;
  color: var(--dark-gray) !important;
  box-shadow: var(--shadow-sm) !important;
}

.MuiTypography-root {
  font-family: var(--heading-font) !important;
}

.MuiPaper-root {
  border-radius: var(--radius-lg) !important;
  transition: all 0.3s ease !important;
}

.MuiCardContent-root {
  padding: 16px 24px !important;
}

.MuiTextField-root .MuiOutlinedInput-root {
  border-radius: var(--radius-md) !important;
  transition: all 0.2s ease-in-out !important;
}

.MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-blue) !important;
  border-width: 2px !important;
}

.MuiTextField-root .MuiInputLabel-root.Mui-focused {
  color: var(--primary-blue) !important;
}

/* Custom Classes */
.text-gradient {
  background: var(--gradient-blue-green);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.text-gradient-reverse {
  background: var(--gradient-green-blue);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.bg-gradient {
  background: var(--gradient-blue-green) !important;
  color: white !important;
}

.bg-gradient-reverse {
  background: var(--gradient-green-blue) !important;
  color: white !important;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.slide-up {
  animation: slideUp 0.5s ease-out;
}